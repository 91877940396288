@import '../variables';

.progress-week-page {
  margin-bottom: 120px !important;

  .page-title {
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 6px;
    }
  }

  .progress-card {
    padding-top: 25px;
    padding-bottom: 25px;

    @include media-breakpoint-down(md) {
      margin-top: 240px;
    }

    .progress-entity-stats {
      margin-bottom: 20px;
    }
  }

  .left-container {
    padding-left: 10%;
    margin-right: 10px;
    flex-grow: 0;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
    }

    .trophy-icon {
      width: 60px;
      height: 65px;

      @include media-breakpoint-down(md) {
        width: 30px;
        height: 33px;
      }
    }

    .schedule-icon {
      width: 60px;
      height: 65px;

      @include media-breakpoint-down(md) {
        width: 30px;
        height: 33px;
      }
    }

    .title {
      font-family: $ClanNarrowMedium;
      font-size: 28px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #374105;
    }
  }

  .block-container {
    margin-top: 20px;
  }

  .btn.btn-primary{
    margin-top: 30px;
  }

}
