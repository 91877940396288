.modal-content{
    .modal-header{
        border-bottom: 0px;
        .close{
            font-weight: 100;
            font-size: 42px;
            line-height: 15px;
            color: $charcoal-grey;
        }
    }
}