@import '../../variables';

.user-profile-modal {
  @include media-breakpoint-up(lg) {
    right: 16%;
    top: 75px;
  }
  
  .user-profile {
    padding: 30px 30px 60px;
    border-radius: 8px;
    box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
    background-color: white;
  
    .title {
      font-family: $ClanNarrowMedium;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: $charcoal-grey;
  
      @include media-breakpoint-down(md) {
        font-size: 24px;
        line-height: 1.33;
      }
    }
  
    label, select, input, .text, .disclainer {
      font-family: $ClanNarrowNews;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: $charcoal-grey;
  
      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 1.5;
      }
    }
  
    .btn-container {
      justify-content: flex-end;
      margin: 0;
    }
  }
}
