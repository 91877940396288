@import '../../../variables';

.progress-card {
  padding: 14px;
  border-radius: 8px;
  box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
  background-color: white;
  margin-bottom: 20px !important;

  @include media-breakpoint-down(md) {
    margin-bottom: 10px !important;
    padding: 20px 10px;
  }

  .progress-card-header {
    align-items: center;
    padding: 0 20px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }

    .icon {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    .title {
      font-family: $ClanNarrowMedium;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      text-align: center;
      color: #374105;
      margin: 0;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 1.5;
      }
    }

    .placeholder {
      width: 20px;
      height: 20px;
    }
  }

  .progress-content-container {
    padding: 15px 46px 0;

    @include media-breakpoint-down(md) {
      padding: 15px 0;
    }
  }
}