@import '../../variables';

.audio-recorder {
  position: absolute;
  bottom: -40px;
  left: -40px;
  right: -40px;
  height: 80px;
  z-index: 1;

  .btns-row {
    align-items: center;
    height: 100%;
  }
}
