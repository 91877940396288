@import '../variables';

.login-page {
    margin-bottom: 120px;
    padding: 0 10px;

    .page-title {
        margin-top: 20px;
    }

    .login-card {
        padding: 40px 25px;
        border-radius: 8px;
        box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
        background-color: white;

        .login-btn {
            width: 100%;
            margin-bottom: 10px;
            text-align: center;

            &.grayed {
                margin-top: 10px;
                background-color: #e2e4e5;

                &:hover {
                    background-color: $locked-color;
                }
            }
        }

        .icons-container {
            justify-content: center;
            padding: 70px 10% 50px;
        }
        
    }
}

.anon-login-modal {

    .modal-header-row {
        justify-content: space-between;
        padding: 20px;

        .modal-title {
            font-family: $ClanNarrowBold;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: -0.34px;
            color: $charcoal-grey;
        }

        .close-icon {
            cursor: pointer;
        }
    }

    .anon-login-modal-btn {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;

        &.white {
            background-color: white;
            border-color: $gross-green-light;
        }
    }
}
