.card {
    border: 2px solid $primary;
    color: $charcoal-grey;
    margin-bottom: 10px;
    flex-direction: row;
    display: flex;
    .icon-container{
        width: 24px;
    }
    .card-body {
        width: auto;
        padding:10px;
        .card-subtitle {
            font-family: $HelveticaNeueBd;
            font-size: 14px;
            font-weight: bold;
            letter-spacing: -0.16px;
            text-transform: uppercase;
        }

        .card-title {
            font-family: $GoetheFFClan;
            font-size: 22px;
            font-weight: bold;
            letter-spacing: -0.25px;       
            margin-bottom: 0px;
        }
    }
    &.done {
        cursor: pointer;
        border-color: $gross-green!important;
        .icon-container{
            margin-top: 10px;
            margin-right: 10px;
        }
    }
    &.current {
        background-color: $gross-green!important;
        color: white!important;
        cursor: pointer;
        .icon-container{
            padding-top: 15px;
        }
    }
    
    &.locked {
        cursor: not-allowed;
        border-color:  $locked-color!important;
        color:  $locked-color!important;
        .icon-container{
            margin-top: 10px;
            margin-right: 10px;
            .icon{
                // opacity: 0.7;
            }
        }

    }

    &.unlocked {
        cursor: pointer;
        border-color: $charcoal-grey!important;
    }
    
}
