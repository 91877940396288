.units-list {
  margin-bottom: 120px !important;
  padding-top: 40px;
  
  .back-btn {
    margin-right: 20px;
  
    .back-icon {
      width: 14px;
      height: 14px;
    }
  }
  
  .units-table {
    td {
      cursor: pointer;
    }
  
    .icon-container {
      display: flex;
      width: 30px;
      height: 22px;
      align-items: center;
      justify-content: center;
  
      .icon {
        width: 14px;
        height: 14px;
      }
    }
  }
}
