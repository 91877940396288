@import '../../../variables';

.entity-container {
  padding: 0 0 20px;

  .trophy-icon-container {
    display: flex;
    flex-grow: 0;
    margin-right: 20px;

    &:not(.last)::after {
      content: '';
      display: block;
      width: 1px;
      height: calc(100% + 20px - 15px);
      background-color: #e2e4e5;
      position: absolute;
      top: 15px;
      left: calc(50% - 1px);
    }

    .trophy-icon {
      width: 30px;
      height: 34px;
      z-index: 5;

      @include media-breakpoint-down(md) {
        width: 24px;
        height: 28px;
      }
    }

    .circle {
      width: 30px;
      height: 30px;
      border-radius: 15px;
      background-color: #e2e4e5;

      @include media-breakpoint-down(md) {
        width: 20px;
        height: 20px;
        margin: 0 2px;
      }
    }
  }
}