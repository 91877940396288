@import '../../variables';

.progress-bar-line {
  width: 100%;
  padding: 0px 0 20px;
  list-style: none;
  position: relative;
  display: flex;
  justify-content: space-between;

  @include media-breakpoint-down(md) {
    padding: 0px 0 20px;
  }

  li {
    text-align: center;
    position: relative;
    width: 100%;
    line-height: 17px;
    height: 20px;
    // border: 1px solid red;
    &:before,
    &:after{
      content: '';
      height: 2px;
      background-color: $light-blue-grey;
      position: absolute;
      z-index: 1;
      width: 100%;
      left: -50%;
      top: 50%;
      transform: translateY(-50%);
      transition: all 0.25s ease-out;

      
    }

    &.is-complete:before, &.is-current:before {
      background-color: $gross-green;
    }

    &:first-child:before,
    &:first-child:after {
      display: none;
    }
    &:first-child{
      // width: 50%;
      width:calc(50% + 40px);
      // border: 1px solid green;
      text-align: left;
    }
    &:last-child{
      // width: 50%;
      width:calc(50% + 40px);
      // border: 1px solid green;
      text-align: right;
      &:before{
        width: 200%;
        left: -100%;        
      }
    }

    &:after {
      background-color: $gross-green;
      width: 0%;
    }

    .icon {
      width: 10px;
      height: 10px;
      background-color: $light-blue-grey;
      fill: $light-blue-grey;
      border-radius: 50%;
      max-width: 100%;
      z-index: 10;
      position: relative;
      transition: all 0.25s ease-out;

      &.is-current {
        fill: $gross-green;
        background-color: $gross-green;
        padding: 5px;
        border: 3px solid $gross-green-light;
      }

      &.is-complete {
        fill: $gross-green;
        background-color: $gross-green;
      }
    }
  }
}
