@import '../../../variables';

.bg-img {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: bottom;
  background-origin: content-box;
  padding: 0 20px;

  &.screen-height {
    height: calc(100vh - #{$header-height} - #{$footer-height});
  }
}

.bg-img-mobile {
  position: absolute;
  top: 240px;
  left: 100px;
  right: 100px;
  z-index: -50;
  height: calc(100vh - #{$header-height-mobile} - #{$footer-height});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top;
  background-origin: content-box;
}