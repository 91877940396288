@import '../../variables';

.unit-recommendation {
  padding: 20px 20px 0;
  border-radius: 8px;
  background-color: $biege;
  margin: 0 -20px 20px !important;

  @include media-breakpoint-down(md) {
    padding: 5px 5px 0;
    margin: 0 0 10px !important;
  }

  .recommendation-card-text {
    font-family: $ClanNarrowNews;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: $charcoal-grey;
    margin-bottom: 12px;

    @include media-breakpoint-down(md) {
      font-size: 8px;
      line-height: 1.5;
      margin-bottom: 5px;
    }

    .recommendation-card-title {
      font-family: $ClanNarrowMedium;
      font-weight: bold;
      margin-bottom: 2px;

      @include media-breakpoint-down(md) {
        margin-bottom: 0;
      }
    }
  }
}