@import '../../variables';

.unit-help {
  width: 60%;
  max-width: 60%;

  @include media-breakpoint-down(md) {
    width: 96%;
    max-width: 96%;
  }

  .close-icon {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }

  .carousel-indicators {
    position: relative;
    margin-top: 20px;

    li {
      border-radius: 12px;
      width: 12px;
      height: 12px;
      background-color: rgba(156, 223, 22, 0.32);
      border: 1px solid rgba(156, 223, 22, 0.32);
      margin: 0 10px;
      flex: 0 0 auto;

      @include media-breakpoint-down(md) {
        width: 10px;
        height: 10px;
        margin: 0 6px;
      }

      &.active{
        background-color: $gross-green;
        border: 1px solid $gross-green;
      }
    }
  }

  .modal-body {
    padding: 40px;

    @include media-breakpoint-down(md) {
      padding: 20px 10px;
    }

    .unit-title {
      margin-top: 20px;
      margin-bottom: 10px;
      font-family: $ClanNarrowNews;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      color: rgba(14, 32, 41, 0.72);
    }
    .carousel.slide {
      position: relative;
      flex: 1;
    }
    .help-content-title-row {
      align-items: center;
      justify-content: center;

      .help-content-title {
        font-family: $GoetheFFClan;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: rgba(14, 32, 41, 0.72);
      }
    }

    .image-container {
      padding-right: 20px;
      padding-left: 0;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        padding-right: 1;
      }

      .help-content-img {
        border-radius: 6px;
        border: solid 1px $gross-green;
        object-fit: contain;
        width: 100%;
      }
    }

    .video-container {
      padding-left: 20px;
      padding-right: 0;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        padding-left: 0;
      }

      .help-content-text-container {
        height: 100px;

        .help-content-text {
          margin-top: 20px;
          font-family: $ClanNarrowNews;
          font-size: 14px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.29;
          letter-spacing: normal;
          color: rgba(14, 32, 41, 0.72);
        }
      }
    }
  }

  .help-footer {
    justify-content: space-between;
    align-items: center;
    margin-top: 40px;

    .carusel-indicators-container {
      width: 142px;
      flex-grow: 0;
    }

    .btn-container {
      flex-grow: 0;
    }
  }  
}
