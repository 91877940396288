@import '../../../../variables';

.progress-state-icon {

  .icon {
    width: 44px;
    height: 50px;

    @include media-breakpoint-down(md) {
      width: 22px;
      height: 25px;
    }
  }
}