@import '../variables';

.progress-units-page {
  margin-bottom: 120px !important;

  .page-title {
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 6px;
    }
  }

  .progress-card {
    padding-top: 25px;

    @include media-breakpoint-down(md) {
      margin-top: 240px;
    }
  }

  .unit-container {
    padding: 20px 0;

    .trophy-icon-container {
      display: flex;
      flex-grow: 0;
      margin-right: 20px;

      &:not(.last)::after {
        content: '';
        display: block;
        width: 1px;
        height: calc(100% + 20px - 15px);
        background-color: #e2e4e5;
        position: absolute;
        top: 15px;
        left: calc(50% - 1px);
      }

      .trophy-icon {
        width: 30px;
        height: 34px;
        z-index: 5;
      }

      .circle {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: #e2e4e5;
      }
    }

    .unit-title {
      font-family: $GoetheFFClan;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #374105;
      margin-top: 1px;
      margin-bottom: 0;
    }

    .date-time-container {
      align-items: center;

      .schedule-icon {
        width: 14px;
        height: 14px;
        margin-right: 5px;
      }

      .clock-icon {
        width: 14px;
        height: 14px;
        margin-left: 20px;
        margin-right: 5px;
      }
    }
  }
}
