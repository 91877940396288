.body {
  display: flex;
  align-items: center;
  justify-content: center;

  .imgwrap {
    width: 100%;
    margin: auto;

    .img {
      display: block;
      width: 100%;
      max-width: 500px;
      height: auto;
      margin: auto;
    }
  }
}
