@import '../../variables';

.task-result {
  padding: 30px 40px 15px 40px;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
  background-color: white;
  margin-bottom: 120px;
  margin-top: 20px !important;

  @include media-breakpoint-down(md) {
    padding: 20px 10px;
  }

  .xs-header-row {
    justify-content: space-between;
    align-items: center;

    .col:last-child {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    p {
      font-family: $ClanNarrowMedium;
      font-size: 12px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #385c0a;
    }
  }

  .title-container {
    justify-content: center;

    .title {
      font-family: $ClanNarrowBold;
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      color: #374105;

      @include media-breakpoint-down(md) {
        font-size: 30px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.27;
        letter-spacing: normal;
      }
    }
  }
  
  table {
    color: #385c0a;

    thead {
      th {
        border-bottom: none;
      }
    }

    th {
      border-top: none;
      border-bottom: solid 1px rgba(200, 185, 135, 0.4);
      font-family: $GoetheFFClan;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      color: #385c0a;

      @include media-breakpoint-down(md) {
        font-family: $ClanNarrowMedium;
        font-size: 16px;
        line-height: 1.5;
      }
    }
    td {
      border-top: none;
      border-bottom: solid 1px rgba(200, 185, 135, 0.4);
      font-family: $ClanNarrowNews;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.56;
      letter-spacing: normal;
      text-align: left;
      color: #385c0a;
      padding: 0.4rem 0.75rem;
      vertical-align: middle;

      @include media-breakpoint-down(md) {
        font-size: 16px;
        line-height: 1.5;
      }

      .icon {
        width: 30px;
        height: 30px;
      }
    }

    th,
    td {
      &:nth-child(2),
      &:nth-child(3) {
        text-align: center;
      }
    }
  }

  .btn-container {
    justify-content: center;
    margin: 30px 0 20px 0;
  }
}
