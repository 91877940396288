@import '../../variables';
.navbar.header{
  height: $header-height;
  background-color: $header-background-color;
  margin: 0;

  @include media-breakpoint-down(md) {
    height: $header-height-mobile;
  }

  .title {
    font-family: $GoetheFFClan;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.11px;
    color: $gross-green;
    margin: 0;
  }

  .content-container {
    justify-content: space-between;
    align-items: center;

    .menu-icon-container {
      display: flex;
      justify-content: flex-end;
    }
  }

  .icon {
    width: 108px;
    height: 50px;
    object-fit: contain;
  }

  .menu-icon {
    width: 30px;
    height: 30px;
    margin-right: 40px;
    cursor: pointer;

    @include media-breakpoint-up(xs) {
      margin-right: 2px;
      width: 25px;
      height: 25px;
    }
  }
}

.header-menu {
  position: absolute;
  right: 58px;
  top: 18px;
  min-width: 291px;

  @include media-breakpoint-down(md) {
    right: 2%;
    margin: 0;
    width: 96%;
    max-width: 96%;
  }

  .modal-body {
    padding: 20px 20px 30px;
    border-radius: 8px;
    box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
    background-color: white;
    border: none;

    @include media-breakpoint-down(md) {
      padding: 20px 10px 20px 20px;
    }

    .row-container {
      margin-bottom: 12px;

      @include media-breakpoint-down(md) {
        margin-bottom: 10px;
      }

      &:not(.disabled) {
        cursor: pointer;
      }

      &.small-gap {
        margin-bottom: 4px;
      }

      p {
        margin: 0;
      }

      .main-title {
        font-family: $GoetheFFClan;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #374105;
        margin-bottom: 10px;

        @include media-breakpoint-down(md) {
          font-size: 18px;
          line-height: 1.33;
        }
      }
    
      .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .left-container {
        flex: 0 0 auto;
        width: 34px;

        .icon {
          width: 25px;
          height: 25px;

          @include media-breakpoint-down(md) {
            width: 22px;
            height: 22px;
          }
        }
      }

      .title {
        font-family: $ClanNarrowMedium;
        font-size: 18px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #374105;

        @include media-breakpoint-down(md) {
          font-size: 14px;
          line-height: 1.43;
          letter-spacing: -0.2px;
        }
      }

      .subtitle {
        font-family: $ClanNarrowNews;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #374105;

        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: 1.5;
        }

        &.active {
          font-family: $ClanNarrowBold;
        }
      }
    }
  }
}
