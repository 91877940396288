
.btn{
    &.btn-primary, &.btn-secondary {   
        font-family: $GoetheFFClan!important; 
        font-size: 18px!important;
        font-weight: bold!important;
        letter-spacing: -0.2px!important;
        .icon {
            width: 22px;
            height: 22px;
            object-fit: contain;
            margin-right: 10px;
        }
    }
    &.btn-primary{
        color: $white!important; 
    }
    &.btn-secondary{
        color: $charcoal-grey;
        border-radius: 2px;
        border: solid 2px $gross-green;
    }
    &.btn-link{
        font-family: $HelveticaNeueBd;
        font-size: 16px;
        letter-spacing: -0.18px;
        color: $charcoal-grey;
        justify-content: center;
        margin-top: 30px;    
        font-weight: bold!important;
    }    
}
