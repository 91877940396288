@import '../../variables';

.lesson-result {
  padding: 30px 40px 40px 40px;
  border-radius: 10px;
  box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
  background-color: white;
  margin-bottom: 120px;
  margin-top: 20px !important;

  @include media-breakpoint-down(md) {
    padding: 20px 5px 10px;
  }

  .schedule-bg-icon-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include media-breakpoint-down(md) {
      width: 30%;
      flex: 0 0 auto;
    }

    .schedule-bg-icon {
      width: 80px;
      height: 80px;
      margin-right: 5px;

      @include media-breakpoint-down(md) {
        width: 60px;
        height: 60px;
      }
    }
  }

  .table-container {
    justify-content: center;
    margin-top: 60px;

    table {
      color: #385c0a;
      max-width: 392px;
  
      tr {
        border: none;
      }
      td {
        border-bottom: solid 1px rgba(200, 185, 135, 0.4);
        font-family: $ClanNarrowNews;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        text-align: left;
        color: #385c0a;

        @include media-breakpoint-down(md) {
          font-size: 16px;
          line-height: 1.5;
        }
  
        .icon {
          width: 30px;
          height: 30px;
        }
      }
  
      th,
      td {
        &:nth-child(2),
        &:nth-child(3) {
          text-align: center;
        }
      }
    }
  }

  .btn-container {
    justify-content: center;
    margin: 20px 0 20px 0;

    @include media-breakpoint-down(md) {
      margin: 10px 0 10px 0;
    }
  }

  .recomendation-container {
    justify-content: center;
    margin-top: 40px;

    @include media-breakpoint-down(md) {
      margin-top: 30px;
    }

    .unit-recommendation {

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }
  }
}
