@import '../../variables';

.circle-progress-bar-container {
  cursor: pointer;
  border-radius: 100%;
  margin: auto;
  position: relative;
  width: 76px;
  height: 76px;
  background-color: white;
}