.page-header {
  clear: all;
  height: 26px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  // @include media-breakpoint-up(xs) {
  //   margin-bottom: 20px;
  // }
  // @include media-breakpoint-up(sm) {
    margin-bottom: 30px;
  // }
  div {
    &.back {
      width: 24px;
      // float: left !important;
      cursor: pointer;
    }
    &.crumb {
      // float: left !important;
      width: calc(100% - 100px);
      flex: 1 1 auto;
      text-align: center;
      font-size: 14px;
      font-family: $HelveticaNeueBd;
      text-transform: uppercase;
      &.no-help{
        width: calc(100% - 25px);
      }
    }
    
  }
}
.page-header, .lesson-help{
  .help {
    width: 26px;
    height: 26px;
    background-color: $robin-s-egg;
    border-radius: 13px;
    padding: 4px;
    // float: right !important;
    cursor: pointer;
    &.labeled {
      width: auto;
      position: relative;
      padding-left: 10px;
      line-height: 18px;
      padding-right: 27px;
      span {
        font-family: $GoetheFFClan;
        color: $white;
        white-space: nowrap;
      }
      .icon {
        position: absolute;
        right: 4px;
        top: 4px;
        display: inline;
      }
    }
  }
}