@import '../../../variables';

.success-result-icon-container {

  .success-result-icon-container-inner {
    width: 40px;
    height: 40px;
    background-color: $gross-green-2;
    border-radius: 100%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .success-result-icon {
      width: 20px;
      height: 15px;
      object-fit: contain;
      color: #fff;
    }
  }
}