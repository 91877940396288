@import '../variables';

.progress-lessons-page {
  margin-bottom: 120px !important;

  .page-title {
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 6px;
    }
  }

  .progress-card {
    padding-top: 25px;

    @include media-breakpoint-down(md) {
      margin-top: 240px;
    }
  }

  .unit-container {
    padding: 20px 0;
    align-items: center;
    cursor: pointer;

    .collapse-icon-container {
      flex-grow: 0;
      padding-left: 10px;
    }

    .unit-icon-container {
      display: flex;
      flex-grow: 0;
      margin-right: 20px;

      .trophy-icon {
        width: 30px;
        height: 34px;
      }

      .circle {
        width: 30px;
        height: 30px;
        border-radius: 15px;
        background-color: #e2e4e5;

        @include media-breakpoint-down(md) {
          width: 20px;
          height: 20px;
        }
      }
    }

    .unit-title-container {
      .unit-title {
        font-family: $GoetheFFClan;
        font-size: 20px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.4;
        letter-spacing: normal;
        color: #374105;
        margin-top: 1px;
        margin-bottom: 0;

        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: 1.33;
        }
      }
    }

    .arrow-up {
      transform: rotate(180deg);
    }
  }

  .lessons-container {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(200, 185, 135, 0.4);
    }
  }

  .lesson-container {
    padding-left: 50px;
  }
}
