@import '../../../variables';

.card-list-item {
  width: 100%;
  flex-grow: 0;
  padding: 18px 20px;
  border-radius: 8px;
  box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
  background-color: white;
  margin-bottom: 20px !important;

  @include media-breakpoint-down(md) {
    padding: 10px;
    margin-bottom: 10px !important;
  }

  &:hover:not(.locked) {
    background-color: #f0f9eb;
    cursor: pointer;
  }

  .card-img-container {
    flex-grow: 0;
    display: flex;
    align-items: center;
    padding: 0;

    .icon-container {
      width: 60px;
      height: 60px;
      flex-grow: 0;
      margin: 0 20px 0 0;
      padding: 10px;
      border-radius: 6px;
      background-color: rgba(200, 196, 135, 0.16);

      @include media-breakpoint-down(md) {
        width: 40px;
        height: 40px;
        margin: 0 10px 0 0;
        border-radius: 4px;
        padding: 5px;
      }

      .icon {
        width: 40px;
        height: 40px;

        @include media-breakpoint-down(md) {
          width: 30px;
          height: 30px;
        }
      }
    }
  }

  .card-title-container {
    padding-right: 30px;

    .card-title-row {
      display: flex;
      justify-content: space-between;
  
      .card-title {
        font-family: $ClanNarrowMedium;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: rgba(14, 32, 41, 0.72);

        @include media-breakpoint-down(md) {
          font-size: 12px;
        }
      }
    }

    .card-subtitle {
      font-family: $ClanNarrowMedium;
      font-size: 24px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #374105;
      hyphens: auto;

      @include media-breakpoint-down(md) {
        font-size: 16px;
      }
    }
  }

  .state-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 0;
    padding: 0;
  }

  .flex-grow-0 {
    flex-grow: 0;
  }

  .margin-right-10 {
    margin-right: 10px;
  }
}