@import '../../variables';

.loader-animation-container {
  width: 62px;
  height: 62px;
  cursor: pointer;
  border-radius: 100%;
  margin: auto;
  position: relative;
  background-color: $light-blue-grey;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-animation {
    width: 20px;
    height: 20px;
    border: 3px solid #9b9b9b20;
    background-color: white;
    border-radius: 10px;
    animation: record-pulse-animation 1.5s cubic-bezier(0.24, 0, 0.38, 1) infinite;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes record-pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}