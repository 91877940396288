@import '../../../variables';

.task-feedback-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;

  .task-feedback-container-inner {
    flex-grow: 0;
    position: relative;
    display: flex;

    .task-feedback-icon {
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 2.5px;
      left: 0;

      @include media-breakpoint-down(md) {
        top: 0;
        width: 16px;
        height: 16px;
      }

      &.error-icon {
        background-image: url('../../../shared/images/new/alert.svg');
      }

      &.hint-icon {
        background-image: url('../../../shared/images/new/tipp.svg');
      }
    }

    .task-feedback-label {
      font-family: $ClanNarrowNews;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      color: $charcoal-grey-dark;
      line-height: 1.5;
      display: inline-block;
      min-height: 27px;

      @include media-breakpoint-down(md) {
        font-size: 12px;
        min-height: 18px;
      }

      &.success-label {
        color: $gross-green;
      }

      &.error-label {
        color: $red;
        padding-left: 25px;
      }

      &.hint-label {
        padding-left: 25px;
      }
    }
  }
}