@import '../../variables';

.counter-container {
  width: 62px;
  height: 62px;
  border-radius: 100%;
  background-color: $gross-green;
  display: flex;
  align-items: center;
  justify-content: center;

  .counter-text {
    font-family: $ClanNarrowNews;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: $charcoal-grey;
  }
}

.recording-container {
  width: 62px;
  height: 62px;
  cursor: pointer;
  border-radius: 100%;
  margin: auto;
  position: relative;
  background: url('../../shared/images/mikrophone.svg');
  background-color: $gross-green;
  background-repeat: no-repeat;
  background-position: center;

  .recording-animation {
    display: block;
    width: 62px;
    height: 62px;
    background-color: $gross-green-light;
    border-radius: inherit;
    transition: all 0.3s linear;
    position: absolute;
    z-index: -1;
  }
}