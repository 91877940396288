@import '~bootstrap/scss/functions';
@import 'variables';
@import '~bootstrap/scss/bootstrap';

@import './scss/button';
@import './scss/card';
@import './scss/page_header';
@import './scss/stats';
@import './scss/modal';

body{
    font-family: $HelveticaNeueLt;
    color: $charcoal-grey;
    background-color: rgba(200, 196, 135, 0.12);
    padding-right: 0 !important;

    h1{
        font-family: $GoetheFFClan;
        font-size: 36px;
        font-weight: bold;
        letter-spacing: -0.34px;
        justify-content: center;
        color: $charcoal-grey;
        margin: 0 0 20px;
    }
    h2{
        font-family: $GoetheFFClan;
        font-size: 22px;
        font-weight: bold;
        letter-spacing: -0.34px;
        justify-content: center;
        color: $charcoal-grey;
        @include media-breakpoint-up(xs) {
            margin: 0 0 10px;
        }
          @include media-breakpoint-up(sm) {
            margin: 0 0 20px;
        }
    
    }
    video{
        &:focus {
            outline: none;
        }
    }

    .page-title {
        font-family: $ClanNarrowBold;
        font-size: 60px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.13;
        letter-spacing: normal;
        color: #374105;

        @include media-breakpoint-down(md) {
            font-size: 30px;
            line-height: 1.27;
        }
    }

    .text-normal {
        font-family: $ClanNarrowNews;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #385c0a;

        @include media-breakpoint-down(md) {
            font-size: 16px;
            line-height: 1.5;
            letter-spacing: normal;
        }
    }

    .unit-number-label {
        font-family: $ClanNarrowMedium;
        font-size: 18px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.33;
        letter-spacing: normal;
        color: #385c0a;
        text-transform: uppercase;

        @include media-breakpoint-down(md) {
            font-size: 12px;
            margin: 0;
        }
    }

    .modal-backdrop.show {
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.4);
    }

    .no-margin {
        margin: 0;
    }

    .hidden {
        visibility: hidden;
    }

    .row {
        margin: 0;
    }

    .col, [class^="col-"] {
        padding: 0;
    }
}

.navbar{
    justify-content: space-between;
}

.global-alerts{
    position: fixed;
    top: $header-height;
    z-index: 1040;
    width: 100%;

    @include media-breakpoint-down(md) {
        top: $header-height-mobile;
    }
}

.modal-open {
    overflow: inherit;
}
