@import '../../../variables';

.colored-text {
  font-family: $ClanNarrowMedium;
  font-weight: 500;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  color: $charcoal-grey-dark;
  position: relative;

  .long-sound {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $charcoal-grey-dark;
  }

  .short-sound {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;

    .short-sound-inner {
      width: 4px;
      height: 4px;
      border-radius: 100%;
      background-color: $charcoal-grey-dark;
    }
  }

  @include media-breakpoint-down(md) {
    line-height: 1.33;
    font-weight: bold;
    font-size: 24px;
  }

  &.colored-text-succeed {
    color: $gross-green-2;

    .long-sound {
      background-color: $gross-green-2;
    }

    .short-sound {
      .short-sound-inner {
        background-color: $gross-green-2;
      }
    }
  }

  &.colored-text-failed {
    color: $red-3;

    .long-sound {
      background-color: $red-3;
    }

    .short-sound {
      .short-sound-inner {
        background-color: $red-3;
      }
    }
  }
}