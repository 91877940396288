@import '../../../variables';

.task-caption {
  text-align: center;
  margin: 20px 20px 30px;

  @include media-breakpoint-down(md) {
    font-size: 24px;
    margin: 5px 20px 35px;
  }

  .article {
    color: $light-blue-grey;
    opacity: 0.4;
    font-family: $ClanNarrowMedium;
    font-size: 28px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    color: #006ec9;

    @include media-breakpoint-down(md) {
      font-size: 24px;
    }
  }
}