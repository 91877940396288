@import '../../../_variables.scss';

.badge {
  height: 24px;
  flex-grow: 0;
  padding: 3px 12px;
  border-radius: 13px;
  background-color: rgba(200, 189, 135, 0.24);
  margin-bottom: 0.75rem;

  @include media-breakpoint-down(md) {
    height: 16px;
    padding: 2px 8px;
  }

  .badge-text {
    flex-grow: 0;
    font-family: $ClanNarrowNews;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.29;
    letter-spacing: normal;
    text-align: left;
    color: #385c0a;

    @include media-breakpoint-down(md) {
      font-size: 8px;
    }
  }
}