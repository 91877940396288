@import '../../variables';
@import '../../pages/Lesson.scss';

.lesson {
    .round-container{
        &.small {
            width: 70%;
            margin: auto;
            padding-top: 3rem;
        }

        .line {
            height: 10px;
            border: 1px solid $white;
            border-radius: 4px;
            background-color: $white;

            &.black{
                background-color: $charcoal-grey;
                border-color: $charcoal-grey;
                width: 50px;
                margin: auto;
                margin-top: 1rem;
                margin-bottom: 3rem;
            }
        }

    }

    .modal-header-row {
        justify-content: space-between;
        padding: 20px;

        @include media-breakpoint-down(md) {
            padding: 10px;
        }

        .close-icon {
            cursor: pointer;
        }
    }

    .modal-btn {
        width: 100%;
        text-align: center;
    }

}