@import '../../../variables';

.progress-entity-stats {

  .entity-title {
    font-family: $GoetheFFClan;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #374105;
    margin-top: 1px;
    margin-bottom: 0;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      line-height: 1.33;
    }
  }

  .date-time-container {
    align-items: center;

    .date-icon {
      width: 14px;
      height: 14px;
      margin-right: 5px;
    }

    .text {
      margin-right: 20px;
      font-family: $ClanNarrowNews;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: #385c0a;

      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 1.5;
      }
    }

    .clock-icon {
      width: 14px;
      height: 14px;
      margin-right: 5px;

      @include media-breakpoint-down(md) {
        width: 12px;
        height: 12px;
      }
    }
  }
}