@import '../../variables';

.visualizer-container__bar {
  background-color: red;
  height: 30px;
  width: 2px;
  margin-right: 2px;
}
#microphone {
  .goethe-audio-analysis-plugin {
    width: 62px;
    height: 62px;

    @include media-breakpoint-down(md) {
      width: 40px;
      height: 40px;
    }

    .goethe-audio-analysis-plugin__record,.goethe-audio-analysis-plugin__loading,    
    .goethe-audio-analysis-plugin__stop {
      width: 62px;
      height: 62px;
      cursor: pointer;
      border: solid 2px $gross-green;
      border-radius: 31px;
      margin: auto;
      background: url('../../shared/images/mikrophone.svg') 13px 9px transparent;
      background-color: $gross-green;
      background-repeat: no-repeat;

      @include media-breakpoint-down(md) {
        width: 40px;
        height: 40px;
      }
    }
    .goethe-audio-analysis-plugin__loading{
      background-color: $light-blue-grey;
    }
    .goethe-audio-analysis-plugin__stop {
      overflow: visible;
      position: relative;
    }
    .goethe-audio-analysis-plugin__stop:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: white;
      border: solid 2px $gross-green;
      border-radius: inherit;
      transition: opacity 0.3s, transform 0.3s;
      animation: record-pulse-animation 1.5s cubic-bezier(0.24, 0, 0.38, 1) infinite;
      z-index: -1;
    }
  }
}

.audio-result-success-icon-container {
  margin-bottom: -20px;
}
