@import '../variables';

.home {
  margin-bottom: 120px;

  .unit-list-container {
    @include media-breakpoint-down(md) {
      margin-top: 230px;
    }
  }
  
  .page-title {
    margin-top: 20px;
  }
}