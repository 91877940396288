@import '../../variables';

.start-record-button {
  width: 62px;
  height: 62px;
  cursor: pointer;
  border-radius: 100%;
  margin: auto;
  position: relative;
  background: url('../../shared/images/mikrophone.svg');
  background-color: $gross-green;
  background-repeat: no-repeat;
  background-position: center;

  &.disabled {
    background-color: $light-blue-grey
  }
}