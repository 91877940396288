@import '../../../variables';

.tips-badge {
  flex-grow: 0;
  padding: 3px 12px;
  border-radius: 20px;
  background-color: rgba(90, 200, 245, 0.43);
  cursor: pointer;
  align-items: center;

  .tips-label {
    flex-grow: 0;
    margin: 0 10px 0 0;
    font-family: $ClanNarrowMedium;
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: -0.2px;
    text-align: left;
    color: #0e2029;

    @include media-breakpoint-down(md) {
      font-size: 14px;
      line-height: 1.43;
    }
  }

  .icon {
    width: 24px;
    height: 24px;

    @include media-breakpoint-down(md) {
      width: 18px;
      height: 18px;
    }
  }
}