@import '../../variables';

.lesson-page {

  .question-container {
    margin: 0;
    padding: 25px 30px 0;
    border-radius: 4px;
    border: solid 1.5px #0e2029;

    @include media-breakpoint-down(md) {
      padding: 10px 10px 0;
    }

    &.round-1 {
    }

    &.failed {
      border: solid 2.5px $red-3;
      border-color: $red-3 !important;
    }
    &.almost {
      border: solid 1.5px $butterscotch !important;
    }

    &.succeed {
      border: solid 1.5px $gross-green-2 !important;
    }

    .xs-tips-row {
      justify-content: flex-end;
    }

    .question-row {
      justify-content: space-between;
      align-items: center;
      margin-bottom: 30px;

      .question-text-container {
        text-align: center;

        @include media-breakpoint-down(md) {
          margin-top: 10px;
        }

        .question-text {
          font-family: $ClanNarrowMedium;
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: normal;
          color: rgba(14, 32, 41, 0.72);
          margin: 0;
        }
      }
    }

    .round-container {
      margin: 0px 20px;
  
      .option-container {
        position: absolute;
        bottom: -20px;
        left: 0;
        right: 0;
        align-items: center;
        display: flex;
        justify-content: space-around;
      }
  
      .icon-button {
        display: flex;
        justify-content: space-around;
        @include media-breakpoint-up(xs) {
          margin-bottom: -31px;
        }
        @include media-breakpoint-up(lg) {
          margin-bottom: -31px;
        }
  
  
        .circle {
          width: 62px;
          height: 62px;
          cursor: pointer;
          border: solid 2px $gross-green;
          border-radius: 31px;
          background-color: $white;
          margin: auto;
          padding: 18px 14px;

          @include media-breakpoint-down(md) {
            width: 54px;
            height: 54px;
            padding: 14px 10px;
          }
  
          &.primary {
            background-color: $gross-green;
            padding: 9px 14px;
          }
  
          .icon {
            width: 32px;
            height: 26px;
            object-fit: contain;
          }
  
          .mikrofon {
            width: 32px;
            height: 44px;
            object-fit: contain;
          }
        }
  
        &.small {
          margin-bottom: -2.5rem !important;
          .circle {
            background-color: $gross-green !important;
            height: 42px !important;
            width: 42px !important;
            padding: 14px 5px !important;
          }
        }
      }
  
      .option {
        width: 40px;
        height: 40px;
        cursor: pointer;
        border-radius: 20px;
        background-color: $gross-green;
        white-space: nowrap;
        display: flex;
        align-items: center;
        justify-content: center;

        font-family: $ClanNarrowMedium;
        font-size: 26px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.23;
        letter-spacing: normal;
        color: #0e2029;
      }
  
      .icon-container {
        width: 60px;
        height: 37px;
        border-radius: 18.5px;
        background-color: $robin-s-egg;
        margin: auto;
        padding: 5px 13px;
  
        .icon {
          width: 34px;
          height: 27px;
          object-fit: contain;
        }
      }
      img.img-fluid {
        @include media-breakpoint-up(xs) {
          height: 150px;
        }
        @include media-breakpoint-up(lg) {
          height: 200px;
        }
        object-fit: none; /* Do not scale the image */
        object-position: center;
        width: 100%;
      }
    }
  }

  .footer {
    text-align: center;
    height: 116px;
    padding-top: 50px;
    z-index: 5;

    .title {
      font-family: $ClanNarrowNews;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.29;
      letter-spacing: normal;
      color: $charcoal-grey-dark;

      @include media-breakpoint-down(md) {
        font-size: 12px;
        line-height: 1.5;
      }
    }
  }
}
