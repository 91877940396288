.back-btn {
  margin-right: 20px;

  .back-icon {
    width: 14px;
    height: 14px;
  }
}

.no-margin {
  margin: 0;
}

.lesson-edit {
  padding-top: 40px;
}
