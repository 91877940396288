.table-checkbox {
  position: relative;
  margin: 0;
}

.search-icon {
  width: 16px;
  height: 16px;
}

.users-list {
  padding-top: 40px;
}