@import '../../../../_variables.scss';

.state-icon-container {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include media-breakpoint-down(md) {
    width: 40px;
    height: 40px;
  }

  .state-icon-inner-container {
    width: 46px;
    height: 46px;
    border-radius: 23px;
    flex-grow: 0;
    background-color: $gross-green;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-breakpoint-down(md) {
      width: 30px;
      height: 30px;
    }

    &.locked {
      background-color: $locked-color-light;
    }

    &.done {
      background-color: $gross-green-light;
    }

    .card-btn-icon {
      width: 45px;
      height: 45px;

      @include media-breakpoint-down(md) {
        width: 28px;
        height: 28px;
      }
    }
  }
}