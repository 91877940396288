@import '../../variables';

.play-recorded-audio-btn-container, .play-audio-animation-container {
  width: 52px;
  height: 35px;
  cursor: pointer;
  border-radius: 20px;
  margin: auto;
  margin-right: 0;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $gross-green;

  .play-recorded-audio-icon {
    width: 25px;
    height: 20px;
    background: url('../../shared/images/audio.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  .bar {
    background-color: white;
    width: 2px;
    height: 2px;
    margin-right: 2px;
  }
}
