@import '../../variables';
.streak-container {
  margin-bottom: 30px;

  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }

  .text-normal {
    @include media-breakpoint-down(md) {
      margin-bottom: 4px;
    }
  }

  .streak {
    text-align: center;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    > div {
      display: inline-block;
      margin: 0px 0px;
    }
    .active {
      .icon-container {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        background-color: $gross-green;
        padding: 3px;
        margin-bottom: 2px;
        margin-top: 6px;
        .icon {
          opacity: 1;
          width: 18px;
          height: 18px;
        }
      }
    }
    .inactive {
      .icon-container {
        width: 24px;
        height: 24px;
        border-radius: 12px;
        margin-bottom: 2px;
        margin-top: 6px;
        background-color: rgba(200, 185, 135, 0.4);
      }
    }
  }
}
