@import '../../variables';

.user-feedback-form {
  .question {
    font-size: 18px;
    font-family: $HelveticaNeueBd;
    margin-bottom: 5px;
    &.blue{
        color:$robin-s-egg
    }    
  }
  .rate,
  .rate-label {
    display: flex;
    justify-content: space-between;
    position: relative;
    .rate-value, .label {
        justify-content: space-between;
    //   flex-grow: 1;
      text-align: center;      
    }
    .rate-value{
        z-index: 10;
        width: 10px;
        justify-content: space-between;
        // div{
        //     width: 10px;
        // }
    }
    .line{
        position: absolute;
        background-color: #000;
        height: 2px;
        width: 100%;
        top: 10px;
        z-index: 0;
    }
  }
  .feedback {
    margin-top: 20px;
  }

  .btn-container {
    justify-content: flex-end;
    margin: 0;
  }
}

.user-feedback-thanks{
  .bold{
    font-family: $HelveticaNeueBd;
  }

  .btn-container {
    justify-content: flex-end;
    margin: 0;
  }
}