@import '../../../variables';

.tips-container {
  width: 20px;
  height: 20px;
  background-color: rgba(90, 200, 245, 0.43);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  .icon {
    width: 14px;
    height: 14px;
  }
}