@import '../../../_variables.scss';

.default-btn {
  padding: 5px 20px;
  border-radius: 24px !important;
  background-color: $gross-green-light-2;

  font-family: $ClanNarrowMedium;
  font-size: 18px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  color: $charcoal-grey-dark-2 !important;

  @include media-breakpoint-down(md) {
    font-size: 14px;
    line-height: 1.43;
    letter-spacing: -0.2px;
    text-align: left;
  }

  &:hover:not(.disabled) {
    background-color: $gross-green;
  }

  .content-container {
    align-items: center;

    .icon-container {
      padding: 0;
    }
  }
}