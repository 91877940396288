@import '../variables';

.unit-page {
  margin-bottom: 120px !important;

  .header-container {
    margin-top: 20px;
  }

  .page-title {
    @include media-breakpoint-down(md) {
      margin-top: 25px;
    }
  }

  .normal-text {
    font-family: $ClanNarrowNews;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    text-align: left;
    color: #385c0a;
  }

  .header-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 30px 0;
    height: 30px;
  }

  .btn-back {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .xs-header-row {
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
  }

  .xs-lessons-list-container {
    margin-top: 200px;
  }
}
