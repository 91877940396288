.unit-edit {
  margin-bottom: 120px !important;
  padding-top: 40px;

  .back-btn {
    margin-right: 20px;
  
    .back-icon {
      width: 14px;
      height: 14px;
    }
  }
  
  .file-uploader-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .uploader-label {
    margin: 0;
    flex: 1;
  }
}
