.back-btn {
  margin-right: 20px;

  .back-icon {
    width: 14px;
    height: 14px;
  }
}

.items-table {
  td {
    cursor: pointer;
  }

  .icon-container {
    display: flex;
    width: 30px;
    height: 22px;
    align-items: center;
    justify-content: center;

    .icon {
      width: 14px;
      height: 14px;
    }
  }
}
