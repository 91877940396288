@import '../variables';

.progress-page {
  padding-bottom: 120px;

  .page-title {
    margin-top: 20px;

    @include media-breakpoint-down(md) {
      margin-bottom: 6px;
    }
  }

  .cards-container {
    @include media-breakpoint-down(md) {
      margin-top: 240px;
    }
  }

  .stats {
    text-align: center;
    border-color: $butterscotch;
    p {
      display: flex;
      justify-content: space-around;
      margin-bottom: 10px;
      padding: 0;
    }
    &:hover {
      background-color: $white;
    }
    &.btn:focus,
    &.btn:active {
      outline: none;
      color: #212529;
      background-color: $white;
      border-color: $butterscotch 0.3;
      box-shadow: none !important;
    }
    .progress {
      height: 5px;
      margin-bottom: 5px;
      background-color: #ffeec4;
      .progress-bar {
        background-color: $butterscotch;
      }
    }
    &.lessons-completed {
      border-color: $robin-s-egg;
      &:focus,
      &:active {
        border-color: $robin-s-egg;
      }
      .media {
        margin-top: 9px;
      }
      .progress {
        background-color: #ceeffc;
        .progress-bar {
          background-color: $robin-s-egg;
        }
      }
    }
    &.best-of-week {
      margin: 20px 0;
      padding-bottom: 0;
      p {
        display: inline-block;
        line-height: 40px;
        font-family: $HelveticaNeueLt;
        font-size: 16px;
      }
      .media {
        display: inline;
        margin-right: 20px;
      }
    }
    &.correct-words {
      .progress {
        background-color: #E3EFB9;
        margin: 0 20px 12px 10px;
        .progress-bar {
          background-color: $gross-green;
        }
      }
    }
  }
  .label {
    font-family: $HelveticaNeueLt;
    line-height: 22px;
  }
  .value {
    font-family: $GoetheFFClan;
    font-size: 22px;
    line-height: 27px;
    margin-bottom: 0 !important;
  }

  .trophy-icon {
    width: 45px;
    height: 50px;
  }

  .note-icon {
    width: 40px;
    height: 50px;
  }

  .trophy-star-icon {
    width: 80px;
    height: 80px;

    @include media-breakpoint-down(md) {
      width: 40px;
      height: 50px;
    }
  }

  .left-small-card-container {
    padding-right: 10px;
  }

  .right-small-card-container {
    padding-left: 10px;
  }

  .checkmark-icon {
    width: 26px;
    height: 26px;

    @include media-breakpoint-down(md) {
      width: 16px;
      height: 16px;
    }
  }

  .medal-icon {
    width: 40px;
    height: 44px;

    @include media-breakpoint-down(md) {
      width: 30px;
      height: 30px;
    }
  }
}
