@import '../../../variables';

.success-streak-modal {
  max-width: 992px;

  @include media-breakpoint-down(md) {
    margin-top: 30%;
  }

  .modal-content {
    padding: 20px;

    @include media-breakpoint-down(md) {
      padding: 0;
    }
  }

  .header-container {
    .close-icon {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }

  .body-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .title {
      font-family: $ClanNarrowBold;
      font-size: 60px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.13;
      letter-spacing: normal;
      color: #374105;

      @include media-breakpoint-down(md) {
        font-size: 30px;
        line-height: 1.27;
      }
    }

    .subtitle {
      font-family: $ClanNarrowMedium;
      font-size: 32px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.25;
      letter-spacing: normal;
      color: #374105;

      @include media-breakpoint-down(md) {
        font-family: $ClanNarrowBold;
        font-size: 24px;
        line-height: 1.33;
      }
    }

    .emblem-container {
      width: 360px;
      height: 320px;
      position: relative;
      text-align: center;

      @include media-breakpoint-down(md) {
        width: 240px;
        height: 220px;
      }

      .emblem-text-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 30px;
      }
    }
  }

  .footer-container {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 30px;

    .subtitle {
      margin: 0;
    }
  }

  .xs-btn-container {
    width: 100%;
    justify-content: flex-end;
    margin-top: 30px;
  }
}