$header-background-color: white;
$header-height: 86px;
$header-height-mobile: 45px;
$footer-height: 95px;
$grid-gutter-width: 20px !default;
$gross-green: #a0c814;
$gross-green-light: #CFE389;
$robin-s-egg: #5ac8f5;
$charcoal-grey: #474d50;
$light-blue-grey: #c5d7e0;
$steel-grey: #788287;
$butterscotch: #ffc538;
$red: #eb6400;
$locked-color:rgba(120, 130, 135, 0.7);
$light-blue: rgba(90, 200, 245, 0.43);

// ReactStrap Variables
$primary: $gross-green;
$secondary: #fff;

$locked-color-light: rgba(14, 32, 41, 0.12);
$charcoal-grey-dark: #515e65;
$charcoal-grey-dark-2: #0e2029;
$gross-green-light-2: rgba(156, 223, 22, 0.32);
$gross-green-light-3: #dff5b4;
$red-2: #e86400;
$red-3: #e10f05;
$gross-green-2: #479b0f;
$biege: #f2efe2;



@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/mixins';

@import '~bootstrap/scss/variables';

@font-face {
    font-family: 'GoetheFFClan';
    src: url("shared/font/GoetheFFClan/ClanCompPro-NarrowMedium.ttf") format("truetype");
}

@font-face {
    font-family: 'HelveticaNeueBd';
    src: url("shared/font/HelveticaNeue/HelveticaNeueBd.ttf") format("truetype");
}
@font-face {
    font-family: 'HelveticaNeue';
    src: url("shared/font/HelveticaNeue/HelveticaNeue.ttf") format("truetype");
}
@font-face {
    font-family: 'HelveticaNeueLt';
    src: url("shared/font/HelveticaNeue/HelveticaNeueLt.ttf") format("truetype");
}

@font-face {
    font-family: 'ClanPro-NarrowMedium';
    src: url('shared/font/ClanNarrow/ClanPro-NarrowMedium.otf') format('opentype');
}

@font-face {
    font-family: 'ClanPro-NarrowBold';
    src: url('shared/font/ClanNarrow/ClanPro-NarrowBold.otf') format('opentype');
}

@font-face {
    font-family: 'ClanPro-NarrowNews';
    src: url('shared/font/ClanNarrow/ClanPro-NarrowNews.otf') format('opentype');
}


$GoetheFFClan: 'GoetheFFClan', sans-serif;
$HelveticaNeueBd: 'HelveticaNeueBd', sans-serif;
$HelveticaNeue: 'HelveticaNeue', sans-serif;
$HelveticaNeueLt: 'HelveticaNeueLt', sans-serif;
$ClanNarrowMedium: 'ClanPro-NarrowMedium', sans-serif;
$ClanNarrowBold: 'ClanPro-NarrowBold', sans-serif;
$ClanNarrowNews: 'ClanPro-NarrowNews', sans-serif;
