.body {
  display: flex;
  align-items: center;
  justify-content: center;

  .videowrap {
    width: 100%;
    margin: auto;

    .video {
      display: block;
      width: 100%;
      max-width: 500px;
      height: auto;
      margin: auto;
    }
  }
}
