@import '../../../variables';

.result-header-row {
  align-items: center;
  justify-content: space-between;

  .header-col {
    white-space: nowrap;

    .header-text {
      font-family: $ClanNarrowMedium;
      font-size: 18px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.33;
      letter-spacing: normal;
      color: #385c0a;
      margin-bottom: 0.5rem;

      &.center {
        text-align: center;
      }
    }
  }
}