@import '../../../variables';

.stars-stats {
  margin-top: -5px !important;

  .star-icon {
    width: 32px;
    height: 32px;
    margin: 0 -4px;

    @include media-breakpoint-down(md) {
      width: 24px;
      height: 24px;
      margin: 0 -3px;
    }

    &:first-child {
      margin-left: -6px;
    }
  }
}
