@import '../../variables';

.navbar.footer {
  background-color: $white;
  box-shadow: 0 -2px 4px 0 rgba(14, 32, 41, 0.2);
  padding: 15px 0;

  @include media-breakpoint-down(md) {
    padding: 10px 0;
  }

  .icon {
    width: 30px;
    height: 30px;

    @include media-breakpoint-down(md) {
      width: 20px;
      height: 20px;
    }
  }

  > div {    
    height: 65px;
    text-align: center;
    font-size: 14px;
    margin: 0;

    @include media-breakpoint-down(md) {
      height: 45px;
    }

    a,
    a:hover,
    a:active,
    a:focus {
      display: block;
      color: $charcoal-grey;
      text-decoration: none;
      &.active{
        color:$gross-green;
        font-weight: bold;
      }
      .subtitle {
        text-align: center;
        padding-top: 12px;
        font-family: $ClanNarrowNews;
        font-size: 18px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.56;
        letter-spacing: normal;
        color: #0e2029;

        @include media-breakpoint-down(md) {
          font-size: 12px;
          line-height: 1.17;
          padding-top: 8px;
        }
      }      
    }
  }
}
