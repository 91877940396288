@import '../variables';

.lesson-page {
  margin-bottom: 120px !important;

  .page-title {
    margin-top: 20px;
  }

  .xs-header-row {
    align-items: center;
    margin-bottom: 10px;
    margin-top: 15px;

    .btn-back {
      cursor: pointer;
      width: 20px;
      height: 20px;
    }
  }
  

  .lesson-card {
    padding: 30px 40px 30px;
    border-radius: 10px;
    box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
    background-color: white;
    margin: 0 0 30px;

    @include media-breakpoint-down(md) {
      padding: 20px 10px 40px;
    }

    .header-row {
      justify-content: space-between;
      margin-bottom: 30px;

      .close-icon {
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      .header-text {
        font-family: $ClanNarrowMedium;
        font-size: 32px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.25;
        letter-spacing: normal;
        text-align: center;
        color: $charcoal-grey;
        margin: 0 15px;

        @include media-breakpoint-down(md) {
          font-size: 18px;
          line-height: 1.33;
        }
      }
    }

    .task-container {
      @include media-breakpoint-down(md) {
        padding: 0 10px;
      }
    }

    .round-number-text {
      font-family: $GoetheFFClan;
      font-size: 20px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: normal;
      text-align: left;
      color: $gross-green;
    }
  }
}
