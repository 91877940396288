@import '../variables';

.privacy{
    h2{
        color:$gross-green;
        font-family: $GoetheFFClan;
    }
    h4{
        font-family: $GoetheFFClan;
        font-size: 1.1rem;
        font-weight: bold;
    }
}