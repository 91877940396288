@import '../../variables';
@keyframes pulse-animation {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 0;
    transform: scale(1.5);
  }
}
.audio-button {
  padding-bottom: 10px;
  button {
    z-index:1;
    &:active {
      outline: none;
    }
    &:focus {
      outline: none;
    }
    &.pulse {
      overflow: visible;
      position: relative;
    }
    &.pulse:before {
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: inherit;
      border-radius: inherit;
      transition: opacity 0.3s, transform 0.3s;
      animation: pulse-animation 1s cubic-bezier(0.24, 0, 0.38, 1) infinite;
      z-index: -1;
    }
    width: 60px;
    height: 40px;
    cursor: pointer;
    margin: auto;
    border: none;
    border-radius: 20px;
    background-color: $light-blue;
    img {
      &.icon {
        width: 34px;
        height: 27px;
      }
    }
  }
}
