@import '../../../variables';

.progress-card {
  padding: 20px 15px;
  border-radius: 8px;
  background-color: white;
  margin: 0 0 20px;

  @include media-breakpoint-down(md) {
    padding: 10px 15px !important;
  }

  &.clickable {
    box-shadow: 2px 2px 6px 2px rgba(200, 185, 135, 0.4);
    cursor: pointer;
    
    &:hover {
      background-color: #f0f9eb;
    }
  }

  .icon-container {
    flex-grow: 0;
    margin-right: 30px;

    @include media-breakpoint-down(md) {
      margin-right: 20px;
    }
  }

  .icon-container-small {
    flex-grow: 0;
    margin-right: 15px;

    @include media-breakpoint-down(md) {
      margin-right: 15px;
    }
  }

  .title {
    font-family: $ClanNarrowMedium;
    font-size: 24px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.33;
    letter-spacing: normal;
    color: #374105;
    margin-bottom: 8px;

    @include media-breakpoint-down(md) {
      font-size: 16px;
      line-height: 1.5;
    }
  }

  .title-small {
    font-family: $ClanNarrowNews;
    font-size: 18px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.56;
    letter-spacing: normal;
    color: #385c0a;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      line-height: 1.5;
      margin-bottom: 5px;
    }
  }

  .value {
    font-family: $GoetheFFClan;
    font-size: 20px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: normal;
    color: #374105;
    padding: 5px 20px;
    border-radius: 93px;
    background-color: rgba(200, 189, 135, 0.24);
    display: flex;
    flex-grow: 0;

    @include media-breakpoint-down(md) {
      font-size: 12px;
      line-height: 1.33;
    }
  }
}