.date-time {
  line-height: 20px;
  font-size: 14px;
  .icon {
    width: 23px;
    height: 23px;
    margin: -5px 3px 0 0;
    &.time{
        margin-left: 10px;
    }
  }
}
